import React from "react";
import './feed.css';
import { useCallback, useState, useEffect } from "react";
import  Field from "../../components/field/Field";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Feed = ( { isLoggedIn, onLogout } ) => {
  const [finderFormData, setFinderFormData] = useState({departure: "", arrival: "", date: "" });
  const [posterFormData, setPosterFormData] = useState({departure: "", arrival: "", estimatedDate: "", totalSeats: "" });
  const [feedDetails, setFeedDetails] = useState([]);
  const [finderError, setFinderError] = useState("");
  const [posterError, setPosterError] = useState("");
  const [isPosterMode, setIsPosterMode] = useState(false);
  const navigate = useNavigate();

  const getAllRides = useCallback (async () => {
    try {
      const response = await axios.get('/api/v1/ride/');

      setFeedDetails(response.data.rides);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else if (error.response) {
          console.log(error.response.data.message);
          setFinderError(error.response.data.error);
        } else {
        setFinderError(error.response.data.error);
        setFeedDetails([]); 
        }    
    }
  }, [onLogout, navigate]);

  useEffect(() => {
    getAllRides();
  }, [getAllRides]);
  
  if (!isLoggedIn) {
    navigate('/');
    return;
  }

  const handleFinderChange = (event) => {
    const { name, value } = event.target;
    setFinderFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleFinderSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get('/api/v1/ride/filterRide/', {
      params: {
        departure: finderFormData.departure,
        arrival: finderFormData.arrival,
        date: finderFormData.date,
      }
    });

    setFeedDetails(response.data.filteredRides);
      
    } catch (error) {
      console.log(error.response.status);
      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else if (error.response.status === 404 ) {
        setFinderError('⚠️ No ride found on this route.');      
      }
    }
  };

  const handleClearFilter = async (event) => {
    event.preventDefault();

    setFinderFormData({departure: "", arrival: "", date: "" });
    setFinderError("");

    getAllRides();
  }

  const handleGetSingleRide = (rideId) => {
   console.log(`handleGetSingleRide triggered with rideId: ${rideId}`);
   
   navigate(`/ride/${rideId}`);
  };
  
  const handlePosterClick = () => {
    setIsPosterMode(true); // Set the state to true when the user clicks Sign Up
    setFinderError("");
  };

  const handleFinderClick = () => {
    setIsPosterMode(false); // Set the state to false when the user clicks Finder
    setPosterError("");
  };

  /****************** */
  const handlePosterChange = (event) => {
    setPosterError(''); // Reset error message

    const { name, value } = event.target;
    setPosterFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handlePosterSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`/api/v1/ride/createRide`, posterFormData);
      
      const newRide = response.data.newRide;

      setFeedDetails((prevRides) => ([
        ...prevRides, 
        newRide, 
      ]));

    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      } else {
        setPosterError(error.response.data.error);
        setPosterError('An error occurred while posting the ride');
      }
    }
  };
  
  return (
    <div className="gpt3__feed section__padding">
      <div className="feed__form">

        <div className="login__form-content">
          <div className="login__form-content__heading">
            <div className={`login__form-content__heading-container ${isPosterMode ? 'shift-right' : ''}`}>
            
              <div className="login__form-content__heading-container__wrapper">
                <h1 className={`gradient__text ${isPosterMode ? 'hide' : ''}`}>Let's find a ride!</h1>
                <p className={`${isPosterMode ? 'hide' : ''}`}>Select one or more filters and press 'Find a Ride'</p>
              </div>
            
              <div className="login__form-content__heading-container__wrapper">
                <h1 className={`gradient__text ${isPosterMode ? '' : 'hide'}`}>Offer Ride!</h1>
                <p className={`${isPosterMode ? '' : 'hide'}`}>Fill in complete details and press 'Post Ride'</p>
              </div>
            
            </div>        
          </div>
          
          <div className="login__form-content__input">
            <div className="login__form-content__input-slide-controls">
              <input type="radio" name="slide" id="finder" checked={!isPosterMode} onChange={handleFinderClick} />
              <input type="radio" name="slide" id="poster" checked={isPosterMode} onChange={handlePosterClick} />
              <label htmlFor="finder" className={`slide`} onClick={handleFinderClick}> Ride Finder </label>
              <label htmlFor="poster" className={`slide`} onClick={handlePosterClick}> Ride Poster </label>
              <div className={`slider-tab ${isPosterMode ? 'shift-right-slider' : ''}`}></div>
            </div>

            <div className={`login__form-content__input-container ${isPosterMode ? 'shift-right' : ''}`}>
              {/* Finder Form */}
              <div className="login__form-content__input-container__wrapper">
                <form onSubmit={handleFinderSubmit} className={`${isPosterMode ? 'hide' : ''}`} >
                  <input type="text" id="departure" name="departure" value={finderFormData.departure} onChange={handleFinderChange} placeholder="Departure"/>
                  <input type="text" id="arrival" name="arrival" value={finderFormData.arrival} onChange={handleFinderChange} placeholder="Arrival"/>
                  <input type="date" id="date" name="date" value={finderFormData.date} onChange={handleFinderChange} placeholder="Date"/>

                  <button type="submit">Find</button>
                  <p onClick={handleClearFilter}><Link to="#">Reset filters</Link></p>
                </form>
              </div>            

              {/* Poster Form */}
              <div className="login__form-content__input-container__wrapper">
                <form onSubmit={handlePosterSubmit} className={`${isPosterMode ? '' : 'hide'}`} >
                  <input type="text" id="departure" name="departure" value={posterFormData.departure} onChange={handlePosterChange} placeholder="Departure"/>
                  <input type="text" id="arrival" name="arrival" value={posterFormData.arrival} onChange={handlePosterChange} placeholder="Arrival"/>
                  <input type="datetime-local" id="date" name="estimatedDate" value={posterFormData.estimatedDate} onChange={handlePosterChange} placeholder="Date"/>
                  <input type="number" id="totalSeats" name="totalSeats" min={1} max={6} value={posterFormData.totalSeats} onChange={handlePosterChange} placeholder="Seats Available"/>

                  {posterError && <p className="profile__form-content__input-error">{posterError}</p>}
                  <button type="submit">Post Ride</button>
                  
                </form>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      
      {(finderError !== "")
      ? <div className="feed__container"> 
          <h2 className="feed__container-error__heading">{finderError}</h2>
        </div>
      : <div className="feed__container">      
          <h2 className="gradient__text">Most Recent</h2>

          <div className="feed__container-content">
          {
            feedDetails.map((element, idx) => {
              const { _id, /*user,*/ arrival, departure, estimatedDate} = element;
              
              let date = new Date(estimatedDate);
              let dateTime = "Date";
              const oneDayInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

              if (Math.abs(date - Date.now()) < oneDayInMs) {
                date = date.toLocaleTimeString(); // Within 24 hours, show time
                dateTime = "Time";
              } else {
                date = date.toLocaleDateString(); // More than 24 hours away, show date
              }
              
              return (
                <div onClick={() => handleGetSingleRide(_id)} className="feed__container-content__post">
                    <Field heading="To" text={arrival}></Field>
                    <Field heading="From" text={departure}></Field>
                    <Field heading={dateTime} text={date}></Field>
                    {/*<Link to={`/profile/${_id}`}><Field heading="Posted by" text={user.userName}></Field></Link>*/}
                  </div>
              );
            })
          }      
          </div>
        </div>
      }
    </div>
  );
}

export default Feed;