import React from "react";
import { useState, useEffect } from "react";
import './login.css';
import { Link, useNavigate  } from "react-router-dom";
import axios from "axios";
import google from  '../../assets/google-icon-logo.svg';

const Login = ( { isLoggedIn, onLogin } ) => {
  const [loginFormData, setLoginFormData] = useState({email: "", password: ""});
  const [signupFormData, setSignupFormData] = useState({userName: "", email: "", password: "", confirmPassword: "", phoneNumber: "", gender: 1});
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const navigate = useNavigate();

  const handleLoginChange = (event) => {
    setError(''); // Reset error message
    setEmailError(''); // Reset error message
    setPasswordError(''); // Reset error message

    const { name, value } = event.target;
    setLoginFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSignupChange = (event) => {
    setError(''); // Reset error message
    setEmailError(''); // Reset error message
    setPasswordError(''); // Reset error message

    const { name, value } = event.target;
    setSignupFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Reset error message
    setEmailError(''); // Reset error message
    setPasswordError(''); // Reset error message
        
    // Frontend validation
    const isEmailValid = /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,4}$/.test(loginFormData.email);
    if (!loginFormData.email || !isEmailValid) {
      setEmailError('⚠️ Please enter a valid email');
      return;
    }
    // Frontend validation
    if (loginFormData.password.length < 7) {
      setPasswordError('⚠️ Password must be atleast 8 characters long');
      return;
    }

    try {
        const response = await axios.post('/api/v1/login', loginFormData);

        onLogin(response.data.user);
        navigate('/feed');
    } catch (error) {
        if (error.response) {
          setError(error.response.data.message);
        } else {
            console.log('An error occurred on log in. Please try again');
            setError('⚠️ An error occurred on log in. Please try again');
        }
    }
  };

  const handleSignupSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Reset error message
    setEmailError(''); // Reset error message
    setPasswordError(''); // Reset error message
    
    // Frontend validation
    if (!signupFormData.userName || !signupFormData.email || !signupFormData.password || !signupFormData.confirmPassword || !signupFormData.phoneNumber) {
      setError('⚠️ All fields are required!.');
      return;
    }
    
    // Frontend validation
    const isEmailValid = /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,4}$/.test(signupFormData.email);
    if (!signupFormData.email || !isEmailValid) {
      setEmailError('⚠️ Please enter a valid email');
      return;
    }
    // Frontend validation
    if (signupFormData.password.length < 7) {
      setPasswordError('⚠️ Password must be atleast 8 characters long');
      return;
    }
    // Frontend validation
    if (signupFormData.password !== signupFormData.confirmPassword) {
      setPasswordError('⚠️ Password did not match');
      return;
    }

    try {
        const response = await axios.post('/api/v1/signup', signupFormData);
        
        navigate('/message', { state: { text: response.data.message } });
    } catch (error) {
        if (error.response) {
          navigate('/message', { state: { text: error.response.data.message } });
          setError(error.response.data.message);
        } else {
          navigate('/message', { state: { text: "⚠️ An error occurred on Signup. Please try again" } });
          setError('⚠️ An error occurred on Signup. Please try again');
        }
    }
  };
  
  const handleGoogleSubmit = async (event) => {
    event.preventDefault();
    
    // Redirect the user to the Google OAuth endpoint
    window.location.href = '/api/v1/auth/google';
  }

  useEffect(() => {
    const handleGoogleAuthCallback = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const userString = queryParams.get('user');
      
      console.log("userString:", userString);

      if (userString) {
        try {
          const user = JSON.parse(userString);
      
          console.log("user:", user);

          onLogin(user);
          navigate('/feed');
        } catch (error) {
          console.error('Error during Google callback:', error);
          // Handle the error (e.g., display error message or redirect to login)
        }
      }
    };

    handleGoogleAuthCallback();
  }, [navigate, onLogin]);

  const handleSignUpClick = () => {
    setIsSignUpMode(true); // Set the state to true when the user clicks Sign Up
  };

  const handleLoginClick = () => {
    setIsSignUpMode(false); // Set the state to false when the user clicks Login
  };
  
  if (isLoggedIn) {
    navigate('/feed');
    return ;
  }

  return (
    <div className="login__form section__padding gradient__bg">
      <div className="login__form-content">
        <div className="login__form-content__heading">
          <div className={`login__form-content__heading-container ${isSignUpMode ? 'shift-right' : ''}`}>
          
            <div className="login__form-content__heading-container__wrapper">
              <h1 className={`gradient__text ${isSignUpMode ? 'hide' : ''}`}>Welcome back!</h1>
              <p className={`${isSignUpMode ? 'hide' : ''}`}>Explore rides.</p>
            </div>
          
            <div className="login__form-content__heading-container__wrapper">
              <h1 className={`gradient__text ${isSignUpMode ? '' : 'hide'}`}>Get on board!</h1>
              <p className={`${isSignUpMode ? '' : 'hide'}`}>It's quick and easy.</p>
            </div>
          
          </div>        
        </div>
        
        <div className="login__form-content__input">
          <div className="login__form-content__input-slide-controls">
            <input type="radio" name="slide" id="login" checked={!isSignUpMode} onChange={handleLoginClick} />
            <input type="radio" name="slide" id="signup" checked={isSignUpMode} onChange={handleSignUpClick} />
            <label htmlFor="login" className={`slide`} onClick={handleLoginClick}> Login </label>
            <label htmlFor="signup" className={`slide`} onClick={handleSignUpClick}> Signup </label>
            <div className={`slider-tab ${isSignUpMode ? 'shift-right-slider' : ''}`}></div>
          </div>

          <div className={`login__form-content__input-container ${isSignUpMode ? 'shift-right' : ''}`}>
            {/* Login Form */}
            <div className="login__form-content__input-container__wrapper">
              <form onSubmit={handleLoginSubmit} className={`${isSignUpMode ? 'hide' : ''}`} >
                <input type="email" id="email" name="email" value={loginFormData.email} onChange={handleLoginChange} placeholder="Email"/>
                {emailError && <label htmlFor="email">{emailError}</label>}
                <input type="password" id="password" name="password" value={loginFormData.password} onChange={handleLoginChange} placeholder="Password"/>
                {passwordError && <label htmlFor="password">{passwordError}</label>}
                {/* Display error or success message */}
                {error && <label>{error}</label>}
                <button type="submit">Log in</button>
                
                <div className="login__form-content__input-separator"><p>or</p></div>
                
                <Link to="/feed" onClick={handleGoogleSubmit}>
                  <div className="login__form-content__input-google__logo">
                    <img src={google} alt="logo" />Log in with Google
                  </div>
                </Link>

                <p onClick={handleSignUpClick}>Don't have an account? <Link>Sign Up</Link></p>
              </form>
            </div>            

            {/* Signup Form */}
            <div className="login__form-content__input-container__wrapper">
              <form onSubmit={handleSignupSubmit} className={`${isSignUpMode ? '' : 'hide'}`} >
                <input type="text" id="userName" name="userName" value={signupFormData.userName} onChange={handleSignupChange} placeholder="User Name"/>
                <input type="email" id="email" name="email" value={signupFormData.email} onChange={handleSignupChange} placeholder="Email"/>
                {emailError && <label htmlFor="email">{emailError}</label>}
                <input type="password" id="password" name="password" value={signupFormData.password} onChange={handleSignupChange} placeholder="Password"/>
                {passwordError && <label htmlFor="password">{passwordError}</label>}
                <input type="password" id="confirmPassword" name="confirmPassword" value={signupFormData.confirmPassword} onChange={handleSignupChange} placeholder="Re-enter password"/>
                {passwordError && <label htmlFor="password">{passwordError}</label>}
                <input type="tel" id="phoneNumber" name="phoneNumber" value={signupFormData.phoneNumber} onChange={handleSignupChange} placeholder="Phone number"/>
                
                <select id="gender" name="gender" value={signupFormData.selectedValue} onChange={handleSignupChange} placeholder="Gender">
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="custom">Custom</option>
                </select>
      
                {/* Display error or success message */}
                {error && <label>{error}</label>}
      
                <button type="submit">Sign Up</button>
                
                <div className="login__form-content__input-separator"><p>or</p></div>
                
                <Link to="/feed" onClick={handleGoogleSubmit}>
                  <div className="login__form-content__input-google__logo">
                    <img src={google} alt="logo" />Sign up with Google
                  </div>
                </Link>
                <p onClick={handleLoginClick}>Already have an account? <Link>Log in</Link></p>
              </form>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;