import React from "react";
import './profile.css';
import { useState, useEffect } from "react";
import  Field from "../../components/field/Field";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Profile = ( { isLoggedIn, loginUser, onLogout } ) => {
  const { profileId } = useParams();
  //const [formData, setFormData] = useState({departure: "", arrival: "", estimatedDate: "", totalSeats: "" });
  const [userRides, setUserRides] = useState([]);
  const [profileUser, setProfileUser] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  //const [error, setError] = useState("");
  const [rideError, setRideError] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    const getUserRides = async () => {
      try {
        let response; 
        if (profileId && profileId !== loginUser._id) {
          response = await axios.get(`/api/v1/profile/${profileId}`);
        } else {
          response = await axios.get(`/api/v1/profile`);
        }

        // Use optional chaining and provide fallback values
        const temp_rides = response.data.rides || [];
        const temp_user = response.data.user || loginUser;
        
        setUserRides(temp_rides);
        setProfileUser(temp_user);

        // Clear the error if there are rides
        if (temp_rides.length > 0) {
          setRideError(null);
        } else {
          setRideError('⚠️ No rides posted yet.');
        }
      } catch (error) {
          if (error.response && error.response.status === 401) {
            console.log('Session expired, redirecting to login...');
            onLogout();
            navigate('/login'); // Redirect user to login
          } else if (error.response) {
            console.log(error.response.data.message);
            setRideError(error.response.data.message);
          } else {
            console.log('⚠️ An error occurred while fetching rides');
            setRideError('⚠️ An error occurred while fetching rides');
          }    
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
  };

    getUserRides();
  }, [profileId, loginUser, onLogout, navigate]);

  if (!isLoggedIn) {
    navigate('/');
    return;
  }

  // Show a loading indicator while data is being fetched
  if (loading) {
    return (
      <div className="profile__ride-container"> 
        <h2 className="gradient__text">Loading Rides ...</h2>
      </div>
    );
  }
  /*
  const handleChange = (event) => {
    setError(''); // Reset error message

    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`/api/v1/ride/createRide`, formData);
      
      const newRide = response.data.newRide;

      setUserRides((prevRides) => ([
        ...prevRides, 
        newRide, 
      ]));

    } catch (error) {
        setError(error.response.data.error);
    }
  };
  */

  const handleGetSingleRide = (rideId) => {
    console.log(`handleGetSingleRide triggered with rideId: ${rideId}`);
    
    navigate(`/ride/${rideId}`);
  };

  return (
    <div className="gpt3__feed section__padding">
      {/*(profileUser._id === loginUser._id ) &&
      <div className="profile__form">
        <div className="profile__form-content">
          <h1 className="gradient__text">Offer Ride</h1>
          <p>Fill in complete details and press 'Post Ride'</p>

          <form onSubmit={handleSubmit} className="profile__form-content__input">
            <input type="text" id="departure" name="departure" value={formData.departure} onChange={handleChange} placeholder="Departure"/>
            <input type="text" id="arrival" name="arrival" value={formData.arrival} onChange={handleChange} placeholder="Arrival"/>
            <input type="datetime-local" id="date" name="estimatedDate" value={formData.estimatedDate} onChange={handleChange} placeholder="Date"/>
            <input type="number" id="totalSeats" name="totalSeats" min={1} max={6} value={formData.totalSeats} onChange={handleChange} placeholder="Seats Available"/>
            
            {error && <p className="profile__form-content__input-error">{error}</p>}
            <button type="submit">Post Ride</button>
          </form>
        </div>
      </div>
      */}

      {
      <div className="profile__form">
        <div className="profile__form-content">
          <h1 className="gradient__text">User Profile</h1>

          <div className="profile__form-content__field">
            <img src={profileUser.avatar} alt="" />
            <div className="profile__form-content__field-div">
              <h3>Name: </h3><p>{profileUser.userName}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Email: </h3><p className="lowercase-letter">{profileUser.email}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Phone Number: </h3><p>{profileUser.phoneNumber || "Nil"}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Role: </h3><p>{profileUser.role}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Gender: </h3><p>{profileUser.gender}</p>
            </div>
            <div className="profile__form-content__field-div">
              <h3>Joined Date: </h3><p>{new Date(profileUser.createdAt).toLocaleDateString()}</p>
            </div>
          </div>
        </div>
      </div>
      }

      <div className="profile__ride-container">      
        <h2 className="gradient__text">{`${profileUser?.userName}'s Rides` || "Offered Rides"}</h2>
        {rideError && <p className="">{rideError}</p>}
        
        <div className="profile__ride-container__content">
        {userRides.length &&
          (userRides.map((element, idx) => {
            const { _id, arrival, departure, estimatedDate, /*totalSeats*/} = element;
            
            return (
              <div onClick={() => handleGetSingleRide(_id)} className="profile__ride-container__content-post">
                <Field heading="To" text={arrival}></Field>
                <Field heading="From" text={departure}></Field>
                <Field heading="Date" text={new Date(estimatedDate).toLocaleDateString()}></Field>
              </div>
            );
          }))
        }      
        </div>
      </div>
    </div>
  );
};

export default Profile;