import React from "react";
import { useState, useEffect } from "react";
import Field from "../field/Field";
import './ride.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faTrashCan, faPlay, faCircleCheck, faCircleXmark, faEye, faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';


const Ride = ( { isLoggedIn, loginUser, onLogout } ) => {
  const { rideId } = useParams();
  const [rideDetails, setRideDetails] = useState(null);
  const [deleteInProcess, setDeleteInProcess] = useState(false); // Ride Deleting state
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  
  const rideUrl = `/api/v1/ride/getOneRide/${rideId}`;

  useEffect(() => {
    const getSingleRide = async () => {
      try {
        console.log('Fetching ride data from:', rideUrl);
        const response = await axios.get(rideUrl);
        
        setRideDetails(response.data.rideWithPosterPassengerInfo);
        setLoading(false); // Data is loaded
      } catch (error) {
          console.log(`[FrontEnd] error in getSingleRide, ${error}`);
          if (error.response && error.response.status === 401) {
            console.log('Session expired, redirecting to login...');
            onLogout();
            navigate('/login'); // Redirect user to login
          } else if (error.response) {
            console.log(error.response.data.message);
            //setError(error.response.data.message);
          } else {
            console.log('⚠️ An error occurred while fetching rides');
            //setError('⚠️ An error occurred while fetching rides');
          }    
      }
    };

    getSingleRide();
  }, [rideUrl, onLogout, navigate]);

  if (!isLoggedIn) {
    navigate('/');
    return null;
  }

  // Show a loading indicator while data is being fetched
  if (loading) {
    return (
      <div className="ride__details section__padding gradient__bg" id="ride">
        <div className="ride__details-heading">
          <h1 className="gradient__text">Loading Ride Detail...</h1>
        </div>  
      </div>
    );
  }

  // Show an indicator while deleting in process
  if (deleteInProcess) {
    return (
      <div className="ride__details section__padding gradient__bg" id="ride">
        <div className="ride__details-heading">
          <h1 className="gradient__text">Deleting Ride...</h1>
        </div>  
      </div>
    );
  }
  
  console.log(rideDetails);
  const { _id, arrival, departure, estimatedDate, isStarted, totalSeats, likes, ridePoster, passengerList } = rideDetails;

  const onBtnClickLikeRide = async (rideID) => {
    try {
      // Optimistically update likes directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        likes: likes + 1,
      }));

      const response = await axios.post(`/api/v1/ride/likeRide/${rideID}?_method=PUT`);      
      console.log("likes: ", response.data.likes);
      
      // Update likes from the response
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        likes: response.data.likes,
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        likes: likes - 1,
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const handleBookRide = async (rideID) => {
    try {
      // Optimistically update passenger directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: totalSeats - 1,
        passengerList: [
          ...passengerList, {
            userID: "Loading",
            name: "Loading...",
            ride: rideID,
          }
        ]
      }));

      const response = await axios.post(`/api/v1/ride/addPassenger/${rideID}`);
      
      // Update totalSeats from the response
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: response.data.updatedTotalSeats,
        passengerList: [
          ...passengerList,
          response.data.newPassenger
        ]
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: totalSeats + 1,
        passengerList: passengerList.filter(
          (passenger) => passenger.userID !== "Loading" // Remove the optimistically added passenger
        ),
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const handleCancelBooking = async (rideID, passengerID) => {
    try {
      // Optimistically update passenger directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: totalSeats + 1,
        passengerList: passengerList.filter(
          (passenger) => passenger.userID !== passengerID // Remove optimistically specific passengerID
        ),
      }));

      const response = await axios.post(`/api/v1/ride/deletePassenger/${rideID}&${passengerID}?_method=PUT`);

      // Update totalSeats & PassengerList from the response
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: response.data.updatedTotalSeats,
        passengerList: passengerList.filter(
          (passenger) => passenger.userID !== response.data.deletedPassengerID // Remove optimistically specific passengerID
        ),
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        totalSeats: totalSeats - 1,
        passengerList: passengerList,
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const handleToggleAvailed = async (rideID, passengerID) => {
    try {
      // Optimistically update isRideAvailed directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        passengerList: passengerList.map(
          (passenger) => passenger.userID === passengerID 
          ? {...passenger, isRideAvailed: !passenger.isRideAvailed} 
          : passenger
        )
      }));

      const response = await axios.post(`/api/v1/ride/toggleAvailed/${rideID}&${passengerID}?_method=PUT`);

      // Update isRideAvailed from the response
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        passengerList: passengerList.map(
          (passenger) =>  passenger.userID === passengerID 
          ? {...passenger, isRideAvailed: response.data.updatedAvailedMark}
          : passenger
        )
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        passengerList: passengerList.map(
          (passenger) => passenger.userID === passengerID 
          ? {...passenger, isRideAvailed: !passenger.isRideAvailed} 
          : passenger
        )
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const onBtnClickStartRide = async (rideID) => {
    try {
      // Optimistically update likes directly in rideDetails
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        isStarted: true,
      }));

      const response = await axios.post(`/api/v1/ride/startRide/${rideID}?_method=PUT`);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        isStarted: response.data.isStarted,
      }));
      setLoading(false); // Data is loaded

    } catch (error) {
      console.log(error);

      // Revert the optimistic update on error
      setRideDetails((prevDetails) => ({
        ...prevDetails,
        isStarted: false,
      }));

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };

  const onBtnClickDeleteRide = async (rideID) => {
    try {
      setDeleteInProcess(true);

      await axios.post(`/api/v1/ride/deleteRide/${rideID}?_method=PUT`);

      setDeleteInProcess(false);
      navigate('/profile');
    } catch (error) {
      console.log(error);
      setDeleteInProcess(false);

      if (error.response && error.response.status === 401) {
        console.log('Session expired, redirecting to login...');
        onLogout();
        navigate('/login'); // Redirect user to login
      }
    }
  };
  
  return (
    <div className="ride__details section__padding gradient__bg" id="ride">
      <div className="ride__details-heading">
        <h1 className="gradient__text">Ride Detail</h1>
      </div>    
      
      <div className="ride__details-content">
        <img src={ridePoster.avatar} alt="" href="/profile"/>
        <Field heading="To" text={arrival}></Field>
        <Field heading="From" text={departure}></Field>
        <Field heading="Date" text={new Date(estimatedDate).toLocaleDateString()}></Field>
        <Field heading="Time" text={new Date(estimatedDate).toLocaleTimeString()}></Field>
        <Field heading="Available Seats" text={totalSeats}></Field>
        <Field heading="Status" text={isStarted ? 'Not Available' : 'Available'}></Field>
        <Link to={`/profile/${ridePoster._id}`}><Field heading="Posted by" text={ridePoster.userName}></Field></Link>

        <div className="ride__details-content__all-btn">
          {ridePoster._id !== loginUser._id && (
            <>
              <button onClick={() => onBtnClickLikeRide(_id)}>{likes}<FontAwesomeIcon icon={faHeart}/></button>

              {totalSeats > 0 && !passengerList.some(el => el.userID === loginUser._id) &&
                <button onClick={() => handleBookRide(_id)} class="book-btn"><FontAwesomeIcon icon={faCircleCheck}/> Book</button>
              }

              {passengerList.some(el => el.userID === loginUser._id) &&
                <button onClick={() => handleCancelBooking(_id, loginUser._id)} class="delete-btn"><FontAwesomeIcon icon={faCircleXmark}/> Unbook</button>
              }
            </>
          )}

          {ridePoster._id === loginUser._id && (
            <>
              <button className="">{likes}<FontAwesomeIcon icon={faHeart}/></button>

              {!isStarted && (
                <>
                  <button onClick={() => onBtnClickStartRide(_id)} class=""><FontAwesomeIcon icon={faPlay}/>Start</button>              
                  <button onClick={() => onBtnClickDeleteRide(_id)} class="delete-btn"><FontAwesomeIcon icon={faTrashCan}/> Delete</button>
                </>
              )} 
            </>
          )}
        </div>
      </div>

      <div className="ride__details-passenger__heading">
        <h2 className="gradient__text">Passengers list:</h2>
      </div> 
      
      {passengerList.map((el, idx) => {
          return (
            <div className="ride__details-passenger__content">
              <Link to={`/profile/${el.userID}`}><p>{`${idx+1}.  ${el.name}`}</p></Link>              
              <div>
                <Link to={`/profile/${el.userID}`}><FontAwesomeIcon icon={faEye}/></Link>
                {ridePoster._id === loginUser._id && (
                  <>
                    {!el.isRideAvailed && <Link><FontAwesomeIcon icon={faCheck} onClick={handleToggleAvailed}/></Link>}
                    {el.isRideAvailed && <Link><FontAwesomeIcon icon={faXmark} onClick={handleToggleAvailed}/></Link>}
                  </>
                )}
                
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

export default Ride;